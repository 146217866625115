import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useToggle } from "../../app/hooks";
import { getParentCount } from "../slice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateParentCount } from "../../api/users";
import { useSnackbar } from "notistack";
import CircularProgress from "@material-ui/core/CircularProgress";

const ParentCount = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const limit = useSelector((state) => state?.limit);
  const parentcount = useSelector((state) => state.parentcount);
  const [isOpen, toggle] = useToggle(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    dispatch(getParentCount({}));
  }, [dispatch, page, limit]);

  const formik = useFormik({
    initialValues: {
      count: parentcount?.count || "",
    },
    enableReinitialize: true,

    onSubmit: (values, { resetForm }) => {
      const { count } = values;
      console.log(values);
      const payload = {
        count: count,
      };
      setLoading(true);
      updateParentCount(payload).then((response) => {
        enqueueSnackbar(response?.message);
        setLoading(false);

        if (response?.error_code === 0) {
          resetForm({});
          dispatch(getParentCount({}));
          setLoading(false);
        }
      });
    },
  });

  return (
    <div className="m-main-content">
      <div className="brand_main">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-12 text-center mt-5">
              <h2>Manage Parents View Count Limit</h2>
            </div>
            <div className="col-12 col-md-12">
              <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-sm-12 col-md-8 col-lg-8 m-auto">
                    <div className="form-groupp">
                      <label className="text_label">Count*</label>
                      <div className="input-containerr">
                        <input
                          type="number"
                          className="form-control add_user_info"
                          {...formik.getFieldProps("count")}
                          placeholder="Count"
                          min="0"
                        />
                        {/* <img
                          src="images/dollar.png"
                          className="input-img"
                          alt=""
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="button_area text-center">
                  <button type="submit" className="save_btn">
                    {loading ? <CircularProgress color="inherit" /> : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ParentCount;
