import React, { useState, useEffect } from "react";
import { Drawer } from "antd";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { addUpdateCourses, addUpdateGothra } from "../../api/offer";
import { getCourseDetails, getGothra } from "../slice";
import { fetchReligions } from "../slice";
import { getCommunityAll } from "../slice";
import { getSubcasteAll } from "../slice";

const AddGothra = ({ show, handleShow, id, status, search }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.courseDetail);
  const { enqueueSnackbar } = useSnackbar();
  const [viewportWidth, setViewportWidth] = useState("");
  const religion = useSelector((state) => state?.religionList);
  const community = useSelector((state) => state?.comminityList);
  const subcaste = useSelector((state) => state?.subcasteList);

  useEffect(() => {
    if (typeof window.innerWidth != "undefined") {
      setViewportWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    dispatch(fetchReligions());
    dispatch(getCommunityAll({ religion_id: "616e64d04f57b0de32cfc71e" }));
    dispatch(getSubcasteAll({ community_id: "6463660e5db3dc8d734810d9" }));
  }, [dispatch]);

  // useEffect(() => {
  //     dispatch(getCourseDetails({ id }))
  // }, [])

  const formik = useFormik({
    initialValues: {
      subcaste: id ? data?.higher_education : "",
      name: id ? data?.name : "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
      name: Yup.string().required("*Required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const { subcaste, name, community_id } = values;
      console.log(values);
      const payload = {
        community_id: "6463660e5db3dc8d734810d9",
        sub_caste: subcaste,
        name: name,
      };
      if (id) {
        payload.gothraId = id;
      }
      addUpdateGothra(payload).then((response) => {
        enqueueSnackbar(response?.message);
        if (response?.error_code === 0) {
          resetForm({});
          dispatch(getGothra({ page: 1 }));
          handleShow();
        }
      });
    },
  });

  return (
    <>
      <Drawer
        placement="right"
        onClose={handleShow}
        visible={show}
        width={viewportWidth > 700 ? "40%" : "100%"}
      >
        <div className="px-5 pb-5">
          <div className="text-center">
            <h2 className="offcanvas-title mb-3">
              {id ? "Edit Gothra" : "Add Gothra"}{" "}
            </h2>
          </div>
          <form className="form-style mt-5" onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="text_label">Select Religion</label>
                <select className="form-control add_user_info">
                  {/* <option value="">
                                        Select Religion
                                    </option> */}
                  {religion?.map((el, i) => {
                    return <option value={el?._id}>{el?.name}</option>;
                  })}
                </select>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 mb-4">
                <label className="text_label">Select Community</label>
                <select
                  className="form-control add_user_info"
                  {...formik.getFieldProps("highereducation")}
                >
                  {community?.map((el, i) => {
                    return <option value={el?._id}>{el?.name}</option>;
                  })}
                </select>
              </div>

              <div className="col-sm-12 col-md-12 col-lg-12 mb-4 mt-4">
                <div className="form-groupp">
                  <label className="text_label">Gothra Name</label>
                  <div className="input-containerr">
                    <input
                      type="text"
                      className="form-control add_user_info"
                      {...formik.getFieldProps("name")}
                      placeholder="Gothra Name"
                    />
                    {/* <img src="images/dollar.png" className="input-img" alt="" /> */}
                  </div>
                  {formik.touched.name && formik.errors.name ? (
                    <span className="error">{formik.errors.name}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="button_area text-center">
              <button type="submit" className="save_btn">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Drawer>
    </>
  );
};

export default AddGothra;
